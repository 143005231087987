<template>
  <div class="meeting">
    <div class="report-content">
      <div class="report-infobox">
        <div class="container">
          <ZoomFrame :scheduleId="scheduleId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoomFrame from "./components/ZoomFrame.vue";
export default {
  name: "Meeting",
  data: function() {
    return {
      meetingId: "",
      scheduleId: this.$route.params.id,
      isProcessing: true
    };
  },
  components: {
    ZoomFrame
  }
};
</script>

<style>
.container-zoom {
  width: 70%;
  height: 50%;
}
</style>
